import { colors } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 0px;
`

export const ChartOuter = styled.div`
  padding: 16px 0;
`

export const Table = styled.table``
export const Td = styled.td<{
  empty?: boolean
  centered?: boolean
  bold?: boolean
  grey?: boolean
}>`
  font-size: smaller;
  padding: 4px 8px;
  border: 1px solid ${colors.backgroundHover()};

  ${({ empty }) =>
    !!empty &&
    css`
      border: none;
      background-color: transparent !important;
    `}

  ${({ centered }) =>
    !!centered &&
    css`
      text-align: center;
    `}

  ${({ bold }) =>
    !!bold &&
    css`
      font-weight: 600;
    `}

  ${({ grey }) =>
    !!grey &&
    css`
      background-color: ${colors.N20};
    `}
`

export const Tr = styled.tr`
  tbody &:hover ${Td} {
    background-color: ${colors.backgroundActive()};
  }
`
