import { TeamFragment } from '../../../../graphql'

import PrincipalUser from './PrincipalUser'
import { Outer } from './styled'

type Props = {
  team: TeamFragment
}

const Info = ({ team }: Props) => {
  return (
    <Outer>
      <PrincipalUser key={team.id} team={team} />
    </Outer>
  )
}

export default Info
