import { Divider as _Divider } from '@blueprintjs/core'
import React, { useCallback, ComponentProps } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import {
  useCreateExecutiveWorkspaceMutation,
  CreateExecutiveWorkspaceInput,
  UserCategory,
  UserFacet,
  CreateExecutiveInput,
  useGetTeamLazyQuery,
} from '../../graphql'
import useValues from '../../lib/useValues'
import MarkdownEditor from '../MarkdownEditor'
import Modal from '../Modal'
import TimeZoneSelect from '../TimeZoneSelect'
import { Field, Checkbox, FieldsRow, TextField } from '../form'
import PreviewOnboardingCallAvailabilityButton from '../onboarding-availability/PreviewOnboardingCallAvailabilityButton'
import TeamSelect from '../teams/Select'

import UserCell from './Cell'
import { SingleUserSelect } from './Select'

const AssistantRow = styled(FieldsRow)`
  align-items: center;
  & > * + * {
    margin-left: 8px;
  }
`

const Divider = styled(_Divider)`
  margin: 16px 0;
`

export type FormValues = Partial<
  Omit<CreateExecutiveWorkspaceInput, 'executiveId'> &
    Pick<
      CreateExecutiveInput,
      'facet' | 'principalUserId' | 'hoursLimit' | 'isAllowedPersonal'
    >
>

export const isValidForm = (formValues: FormValues) => {
  if (!formValues.workingHoursTimeZone || !formValues.teamId) {
    return false
  }

  return true
}

type FormProps = {
  values: FormValues
  onPatch: (values: Partial<FormValues>) => void
  isCreatingExecutive?: boolean
}

export const Form = ({
  values,
  onPatch,
  isCreatingExecutive = false,
}: FormProps) => {
  const [getTeamById, { loading: getTeamLoading }] = useGetTeamLazyQuery({
    onCompleted: ({ team: { principalUser } }) => {
      // populate the team principal
      onPatch({ principalUserId: principalUser.id })
    },
  })

  const onSelectTeam = useCallback(
    (teamId: string | null | undefined) => {
      onPatch({ teamId: teamId || null, principalUserId: undefined })

      if (!teamId) return

      // try to populate the team principal
      getTeamById({
        variables: {
          id: teamId,
        },
      })
    },
    [getTeamById, onPatch],
  )

  return (
    <>
      <Field label={'Team'} isRequired>
        <TeamSelect
          value={values.teamId ? { id: values.teamId } : undefined}
          isClearable
          onChange={(team) => onSelectTeam(team?.id)}
          placeholder={'Assign team'}
          maxMenuHeight={250}
          validationState={!values.teamId ? 'error' : undefined}
        />
      </Field>
      {Boolean(values.teamId) && !getTeamLoading && (
        <>
          <Field
            isRequired
            label={
              values.facet === UserFacet.TEAM_MEMBER
                ? 'Team Principal'
                : 'Significant other of'
            }
          >
            <UserCell
              user={
                values.principalUserId
                  ? { id: values.principalUserId }
                  : undefined
              }
              size={'small'}
            />
          </Field>
          {isCreatingExecutive && (
            <FieldsRow>
              <Field label={'Hours Limit'}>
                <TextField
                  type={'number'}
                  placeholder={'No limit'}
                  min={1}
                  step={1}
                  value={values.hoursLimit || undefined}
                  onChangeValue={(value) =>
                    onPatch({ hoursLimit: value ? parseInt(value) : null })
                  }
                />
              </Field>
              <Field label={'Personal Tasks are...'} isRequired>
                <Checkbox
                  isChecked={!!values.isAllowedPersonal}
                  onChangeValue={(isAllowedPersonal) =>
                    onPatch({ isAllowedPersonal })
                  }
                  isIndeterminate={values.isAllowedPersonal == null}
                  isInvalid={values.isAllowedPersonal == null}
                  label={
                    values.isAllowedPersonal == null
                      ? ''
                      : values.isAllowedPersonal
                      ? 'Allowed'
                      : 'NOT allowed'
                  }
                />
              </Field>
            </FieldsRow>
          )}
        </>
      )}

      <Divider />

      <Field label={'Working Hours Time-Zone'} isRequired>
        <TimeZoneSelect
          value={values.workingHoursTimeZone}
          onChangeValue={(workingHoursTimeZone) =>
            // @ts-ignore
            onPatch({ workingHoursTimeZone })
          }
          validationState={!values.workingHoursTimeZone ? 'error' : undefined}
        />
      </Field>

      <Field label={'Add assistant to workspace'}>
        <AssistantRow>
          <SingleUserSelect
            value={values.assistantId ? { id: values.assistantId } : undefined}
            categories={[
              UserCategory.ASSISTANT,
              UserCategory.SANDBOX,
              UserCategory.TEAM,
            ]}
            isClearable
            onChange={(user: null | { id: string }) => {
              onPatch({
                assistantId: user?.id || null,
                sendOnboardingEmail: !!user?.id,
              })
            }}
            maxMenuHeight={130}
          />
          {!!(values.workingHoursTimeZone && values.assistantId) && (
            <PreviewOnboardingCallAvailabilityButton
              timeZone={values.workingHoursTimeZone}
              assistantId={values.assistantId}
            />
          )}
        </AssistantRow>
      </Field>

      <Field
        label={'Matching Rationale'}
        helperMessage={'2 to 3 bullets, visible to exec'}
      >
        <MarkdownEditor
          value={values.matchingRationale}
          onChange={(matchingRationale) => onPatch({ matchingRationale })}
          minHeight={90}
          isDisabled={!values.assistantId}
        />
      </Field>

      <Field label={'Actions'}>
        <Checkbox
          isChecked={!!values.sendOnboardingEmail}
          onChangeValue={(sendOnboardingEmail) =>
            onPatch({ sendOnboardingEmail })
          }
          label={'Send onboarding email & SMS'}
          isDisabled={!values.assistantId}
        />
      </Field>
    </>
  )
}

type Props = ComponentProps<typeof Modal> & {
  executiveId: string
}

const CreateExecutiveWorkspaceModal = ({
  executiveId,
  ...modalProps
}: Props) => {
  const history = useHistory()

  const [formValues, { reset, patch }] = useValues<FormValues>({
    facet: UserFacet.TEAM_MEMBER,
  })

  const [
    createExecutiveWorkspace,
    { loading: loadingCreateExecutiveWorkspace },
  ] = useCreateExecutiveWorkspaceMutation({
    onCompleted: (data) => {
      modalProps.onClose?.()
      history.push(
        `/users/${executiveId}/workspaces/${data.createExecutiveWorkspace.workspace.id}`,
      )
      window.location.reload()
    },
  })

  const onSubmit = useCallback(() => {
    if (formValues.workingHoursTimeZone && formValues.teamId) {
      createExecutiveWorkspace({
        variables: {
          input: {
            ...formValues,
            workingHoursTimeZone: formValues.workingHoursTimeZone,
            executiveId,
            // @ts-ignore - explicitly ignore the principalUserId and facet args when only creating a workspace
            principalUserId: undefined,
            facet: undefined,
          },
        },
      })
    }
  }, [createExecutiveWorkspace, executiveId, formValues])

  return (
    <Modal
      onCloseComplete={reset}
      width={'small'}
      heading={'Create new workspace'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: onSubmit,
          isDisabled: !isValidForm(formValues),
          isLoading: loadingCreateExecutiveWorkspace,
        },
        { text: 'Cancel', onClick: modalProps.onClose },
      ]}
      {...modalProps}
    >
      <Form values={formValues} onPatch={patch} />
    </Modal>
  )
}

export default CreateExecutiveWorkspaceModal
