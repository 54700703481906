import Banner from '@atlaskit/banner'
import Button from '@atlaskit/button'
import { Label } from '@atlaskit/form'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo, useState } from 'react'

import Modal from '../../../../components/Modal'
import Toggle from '../../../../components/Toggle'
import {
  AdhocTaskAcceptabilityStatus,
  AdhocTodoSettingsQuery,
  useAdhocTodoSettingsQuery,
  useUpdateAdhocTodoSettingsMutation,
} from '../../../../graphql'
import useIsAllowedOperation from '../../../../hooks/useIsAllowedOperation'
import enumKeys from '../../../../lib/enumKeys'
import useSwitch from '../../../../lib/useSwitch'

import PromptEditor from './PromptEditor'
import {
  ButtonContainer,
  HeaderFooterContainer,
  Outer,
  HelpText,
  PromptText,
  ToggleContainer,
} from './styled'

interface Props {
  data: AdhocTodoSettingsQuery
  hasEditPermission: boolean
  onClose: () => void
}

const AdhocTodoSettingsModal = ({
  data,
  hasEditPermission,
  onClose,
}: Props) => {
  const [updateAdhocTodoSettings, { loading: mutationLoading }] =
    useUpdateAdhocTodoSettingsMutation()

  const [systemPromptState, setSystemPromptState] = useState<string>(
    data?.adhocTodoSettings.systemPrompt,
  )

  const [titlePromptState, setTitlePromptState] = useState<string>(
    data?.adhocTodoSettings.titlePrompt,
  )

  const [taskAcceptabilityPromptState, setTaskAcceptabilityPromptState] =
    useState<string>(data.adhocTodoSettings.taskAcceptabilityPrompt)

  const [isOpenForBusinessSwitchState, , , toggleOpenForBusiness] = useSwitch(
    data?.adhocTodoSettings.isOpenForSubmission ?? false,
  )

  const onSave = useCallback(() => {
    updateAdhocTodoSettings({
      variables: {
        input: {
          systemPrompt: systemPromptState,
          titlePrompt: titlePromptState,
          taskAcceptabilityPrompt: taskAcceptabilityPromptState,
          isOpenForSubmission: isOpenForBusinessSwitchState,
        },
      },
    })
  }, [
    isOpenForBusinessSwitchState,
    systemPromptState,
    taskAcceptabilityPromptState,
    titlePromptState,
    updateAdhocTodoSettings,
  ])

  const isOpenForBusinessSwitchHasUnsavedChanges = useMemo(
    () =>
      isOpenForBusinessSwitchState !==
      data.adhocTodoSettings.isOpenForSubmission,
    [data.adhocTodoSettings.isOpenForSubmission, isOpenForBusinessSwitchState],
  )

  const systemPromptHasUnsavedChanges = useMemo(
    () => systemPromptState !== data.adhocTodoSettings.systemPrompt,
    [data.adhocTodoSettings.systemPrompt, systemPromptState],
  )

  const titlePromptHasUnsavedChanges = useMemo(
    () => titlePromptState !== data.adhocTodoSettings.titlePrompt,
    [data.adhocTodoSettings.titlePrompt, titlePromptState],
  )

  const taskAcceptabilityPromptHasUnsavedChanges = useMemo(
    () =>
      taskAcceptabilityPromptState !==
      data.adhocTodoSettings.taskAcceptabilityPrompt,
    [
      data.adhocTodoSettings.taskAcceptabilityPrompt,
      taskAcceptabilityPromptState,
    ],
  )

  // const LABELS = 'System', 'Title Shortening', 'Task Acceptability']

  const taskAcceptabilityPromptChildren = (
    <>
      <HelpText>{`This following is always appended to the prompt: `}</HelpText>
      <PromptText>
        {`The response should be one of the following:
${enumKeys(AdhocTaskAcceptabilityStatus).join(', ')}
Always reply.`}
      </PromptText>
    </>
  )

  const tabs = [
    {
      label: 'System',
      hasUnsavedChanges: systemPromptHasUnsavedChanges,
      value: systemPromptState,
      onChangeValue: setSystemPromptState,
      defaultValue: data.adhocTodoSettings.systemPrompt,
    },
    {
      label: 'Title Shortening',
      hasUnsavedChanges: titlePromptHasUnsavedChanges,
      value: titlePromptState,
      onChangeValue: setTitlePromptState,
      defaultValue: data.adhocTodoSettings.titlePrompt,
    },
    {
      label: 'Task Acceptability',
      hasUnsavedChanges: taskAcceptabilityPromptHasUnsavedChanges,
      value: taskAcceptabilityPromptState,
      onChangeValue: setTaskAcceptabilityPromptState,
      defaultValue: data.adhocTodoSettings.taskAcceptabilityPrompt,
      children: taskAcceptabilityPromptChildren,
    },
  ]

  const hasUnsavedChanges = useMemo(
    () =>
      systemPromptHasUnsavedChanges ||
      titlePromptHasUnsavedChanges ||
      taskAcceptabilityPromptHasUnsavedChanges ||
      isOpenForBusinessSwitchHasUnsavedChanges,
    [
      isOpenForBusinessSwitchHasUnsavedChanges,
      systemPromptHasUnsavedChanges,
      taskAcceptabilityPromptHasUnsavedChanges,
      titlePromptHasUnsavedChanges,
    ],
  )

  const updatedAt = data?.adhocTodoSettings.updatedAt

  const formattedUpdatedAt = useMemo(
    () =>
      typeof updatedAt === 'string'
        ? DateTime.fromISO(updatedAt).toRelative()
        : DateTime.fromJSDate(updatedAt).toRelative(),
    [updatedAt],
  )

  const openForBusinessToggleClick = useCallback(() => {
    if (
      confirm(
        `Are you sure you want to turn the entire pool product ${
          isOpenForBusinessSwitchState ? 'OFF' : 'ON'
        }?`,
      )
    ) {
      toggleOpenForBusiness()
    }
  }, [isOpenForBusinessSwitchState, toggleOpenForBusiness])

  const onCloseConfirm = useCallback(() => {
    if (
      !hasUnsavedChanges ||
      window.confirm(
        'You have unsaved changes. Are you sure you want to close?',
      )
    ) {
      onClose()
    }
  }, [hasUnsavedChanges, onClose])

  return (
    <>
      <HeaderFooterContainer>
        <h1>{'🤖 AdhocTodo Settings'}</h1>
        <ToggleContainer>
          <Label htmlFor={'OpenForBusiness'}>{'Open for Business'}</Label>
          <Toggle
            id={'OpenForBusiness'}
            label={'Open for Business'}
            isChecked={isOpenForBusinessSwitchState}
            isDisabled={mutationLoading || !hasEditPermission}
            onChange={openForBusinessToggleClick}
          />
        </ToggleContainer>
      </HeaderFooterContainer>
      {!hasEditPermission && (
        <Banner
          appearance={'warning'}
          isOpen
        >{`You do not have permissions to edit the Ad Hoc Todo settings`}</Banner>
      )}

      <span>{'Prompts'}</span>
      <Tabs id={'default'}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.label}>
              {tab.hasUnsavedChanges && '✨'}
              {tab.label}
            </Tab>
          ))}
        </TabList>
        {tabs.map((tab) => (
          <TabPanel key={tab.label}>
            <PromptEditor
              value={tab.value}
              onChangeValue={tab.onChangeValue}
              defaultValue={tab.defaultValue}
              isDisabled={mutationLoading || !hasEditPermission}
              children={tab.children}
            />
          </TabPanel>
        ))}
      </Tabs>
      <HeaderFooterContainer>
        {`Last updated: ${formattedUpdatedAt}`}
        <ButtonContainer>
          {hasUnsavedChanges && hasEditPermission ? (
            <>
              <Button
                onClick={onCloseConfirm}
                appearance={'subtle'}
                isDisabled={mutationLoading}
              >
                {'Cancel'}
              </Button>
              <Button
                onClick={onSave}
                appearance={'primary'}
                isDisabled={mutationLoading}
              >
                {'Save'}
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={onClose}
                appearance={'primary'}
                isDisabled={mutationLoading}
              >
                {'Close'}
              </Button>
            </>
          )}
        </ButtonContainer>
      </HeaderFooterContainer>
    </>
  )
}

interface ContainerProps {
  onClose: () => void
}

const SettingsModalContainer = ({ onClose }: ContainerProps) => {
  const isAllowed = useIsAllowedOperation()
  const hasEditPermission = isAllowed('Mutation.updateAdhocTodoSettings')
  const { data, error } = useAdhocTodoSettingsQuery()

  return (
    <Modal
      isOpen
      onClose={onClose}
      shouldCloseOnEscapePress={!hasEditPermission}
      shouldCloseOnOverlayClick={!hasEditPermission}
    >
      <Outer>
        {data ? (
          <AdhocTodoSettingsModal
            data={data}
            hasEditPermission={hasEditPermission}
            onClose={onClose}
          />
        ) : error ? (
          <h3>{'Error loading settings. Try again later.'}</h3>
        ) : (
          <h3>{'Loading settings...'}</h3>
        )}
      </Outer>
    </Modal>
  )
}

export default React.memo(SettingsModalContainer)
