import { DatePicker } from '@atlaskit/datetime-picker'
import { FormSection } from '@atlaskit/form'
import { colors, borderRadius } from '@atlaskit/theme'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { transparentize } from 'polished'
import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import styled, { css } from 'styled-components'

import ErrorBanner from '../../../components/ErrorBanner'
import { LoadingSpinner } from '../../../components/Spinner'
import { Field, FieldsRow } from '../../../components/form'
import {
  ChartOuter,
  Outer,
  Table,
  Td,
} from '../../../components/reporting/styled'
import { formatNumber } from '../../../components/reporting/utils'
import {
  onlyIfAssistantUser,
  useGetAssistantUserWorkspaceReportsQuery,
  UserCategory,
  WorkspaceReportFragment,
} from '../../../graphql'

const COLORS = [
  colors.P200,
  colors.Y200,
  colors.T200,
  colors.R200,
  colors.G200,
  colors.B200,
  colors.P400,
  colors.Y400,
  colors.T400,
  colors.R400,
  colors.G400,
  colors.B400,
]

const WEEKS_COUNT = 6
const MONTHS_COUNT = 4

function formatMonth(month: number): string {
  const localDate = new Date(2021, month - 1, 1)
  return localDate.toLocaleDateString(undefined, { month: 'short' })
}

function formatWeek(someDay: string): string {
  const [year, month, day] = someDay.split('-')
  const localDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
  return localDate.toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
  })
}

const getWorkspaceLabel = (workspace: WorkspaceReportFragment['workspace']) =>
  `${
    workspace
      ? workspace.executives[0]?.profile?.displayName || workspace.id
      : 'Double'
  }${workspace?.hasHadCoverage ? ' (TC)' : ''}`

function sortReports(
  reports: WorkspaceReportFragment[],
): WorkspaceReportFragment[] {
  return sortBy(
    reports,
    // Miranda Priestly at the bottom
    ({ workspace }) =>
      workspace?.executives[0]?.id === '5f3ef4ff37cab300b14d56fc' ? 'b' : 'a',
    // "group" by teams
    ({ workspace }) =>
      workspace?.executives[0]?.principalUser?.id ||
      workspace?.executives[0]?.id,
    // In team, put principal user at the top
    ({ workspace }) => (workspace?.executives[0]?.principalUser ? 'b' : 'a'),
    // then sort by name
    ({ workspace }) => getWorkspaceLabel(workspace),
  )
}

const Tr = styled.tr<{ isFaded?: boolean }>`
  tbody &:hover ${Td} {
    background-color: ${colors.backgroundActive()};
    /* color: ${colors.textHover()}; */
  }

  ${({ isFaded }) =>
    !!isFaded &&
    css`
      color: ${colors.N400};
      td:first-child {
        padding-left: 16px;
      }
    `}
`

interface Props extends ComponentProps<'div'> {
  userId: string
}

const Reporting = ({ userId }: Props) => {
  const [fromDate, setFromDate] = useState<string>()
  const [toDate, setToDate] = useState<string>()

  const [selectedDatasetIndexes, setSelectedDatasetIndexes] = useState<
    Set<number>
  >(new Set())

  const toggleDatasetIndex = useCallback(
    (_event: unknown, { datasetIndex }: { datasetIndex: number }) => {
      setSelectedDatasetIndexes((prev) => {
        const next = new Set(prev)
        if (next.has(datasetIndex)) {
          next.delete(datasetIndex)
        } else {
          next.add(datasetIndex)
        }

        return next
      })
    },
    [setSelectedDatasetIndexes],
  )

  const { data, loading, error } = useGetAssistantUserWorkspaceReportsQuery({
    variables: {
      userId,
    },
  })

  const customReportQuery = useGetAssistantUserWorkspaceReportsQuery({
    variables: {
      userId,
      quartersCount: 0,
      monthsCount: 0,
      weeksCount: !(toDate && fromDate)
        ? 0
        : 2 +
          (new Date(toDate).getTime() - new Date(fromDate).getTime()) /
            (7 * 24 * 3_600_000),
      until: toDate && new Date(toDate),
    },
    skip: !(fromDate && toDate),
  })

  const user = onlyIfAssistantUser(data?.user)

  const reports = user?.reports || []
  const customRangeReports =
    fromDate && toDate
      ? onlyIfAssistantUser(customReportQuery.data?.user)?.reports || []
      : null
  const workspaceReports = sortReports(
    (customRangeReports || reports).filter(({ workspace }) => !!workspace),
  )
  let { executives: executiveReports, other: otherReports } = groupBy(
    reports,
    ({ workspace }) =>
      workspace?.executives[0]?.category === UserCategory.EXECUTIVE
        ? 'executives'
        : 'other',
  )

  if (!executiveReports) {
    // @ts-ignore
    executiveReports = []
  }
  if (!otherReports) {
    // @ts-ignore
    otherReports = []
  }

  const chartWeeksCount = reports.reduce(
    (count, { weeklyUsages }) =>
      Math.min(count || Infinity, weeklyUsages.length),
    0,
  )

  let chartColorIndex = -1
  let chartColorUserId: string | undefined
  const getChartColor = (workspace: WorkspaceReportFragment['workspace']) => {
    if (
      chartColorIndex < 0 ||
      !chartColorUserId ||
      chartColorUserId !== workspace?.executives[0]?.principalUser?.id
    ) {
      chartColorUserId = workspace?.executives[0]?.id
      chartColorIndex++
    }

    return COLORS[chartColorIndex]
  }

  const hasTemporaryCoverageWorkspace = useMemo(
    () => workspaceReports.some(({ workspace }) => workspace?.hasHadCoverage),
    [workspaceReports],
  )

  return (
    <Outer>
      <ErrorBanner error={error} />

      <FormSection title={'Hours delegated per week per executive'}>
        <Field>
          <FieldsRow>
            <DatePicker
              placeholder={'From'}
              value={fromDate}
              maxDate={toDate}
              onChange={setFromDate}
            />
            <DatePicker
              placeholder={'To'}
              value={toDate}
              minDate={fromDate}
              onChange={setToDate}
            />
          </FieldsRow>
        </Field>
        <ChartOuter>
          <Bar
            height={200}
            options={{
              legend: {
                labels: {
                  fontColor: colors.text(),
                },
                position: 'bottom',
                align: 'start',
                onClick: toggleDatasetIndex,
              },
              tooltips: {
                backgroundColor: transparentize(
                  0.1,
                  /*colors.heading()*/ '#172B4D',
                ),
                cornerRadius: borderRadius(),
                callbacks: {
                  beforeTitle: ([{ datasetIndex }]: {
                    datasetIndex: number
                  }[]) =>
                    getWorkspaceLabel(
                      workspaceReports[datasetIndex]?.workspace,
                    ),
                  title: ([{ label }]: { label: string }[]) =>
                    'Week of ' + label,
                  label: ({ value }: { value: string }) => value + ' hrs',
                },
              },
              scales: {
                xAxes: [
                  {
                    type: 'time',
                    distribution: 'series',
                    bounds: 'ticks',
                    time: {
                      unit: 'week',
                      tooltipFormat: 'll',
                      displayFormats: {
                        week: 'W',
                      },
                    },
                    stacked: true,
                    gridLines: {
                      color: colors.N40,
                    },
                    ticks: {
                      fontColor: colors.N300,
                      // add 1 to week number because we start our weeks on Sunday, which puts us 1 week behind
                      callback: (value: string) => Number(value) + 1,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      color: colors.N40,
                    },
                    ticks: {
                      min: 0,
                      stepSize: 5,
                      fontColor: colors.N300,
                      callback(value: number) {
                        return `${value} hr${value !== 1 ? 's' : ''}`
                      },
                    },
                  },
                ],
              },
            }}
            data={{
              datasets: workspaceReports.map(
                ({ workspace, weeklyUsages }, index) => ({
                  label: getWorkspaceLabel(workspace),
                  backgroundColor: getChartColor(workspace),
                  hidden: !selectedDatasetIndexes.size
                    ? false
                    : !selectedDatasetIndexes.has(index),
                  data: weeklyUsages
                    .slice(0, chartWeeksCount)
                    .map(({ hours, sunday }) => ({
                      y: formatNumber(hours),
                      // add 5 hours to handle non-DST time zone offset
                      t: new Date(new Date(sunday).getTime() + 5 * 3_600_000),
                    })),
                }),
              ),
            }}
          />
        </ChartOuter>
      </FormSection>

      {hasTemporaryCoverageWorkspace && (
        <div>{'* (TC) = Temporary Coverage'}</div>
      )}

      <FormSection title={`Last ${WEEKS_COUNT} weeks`}>
        <Table cellSpacing={'0'} cellPadding={'0'}>
          <thead>
            <Tr>
              <Td empty />
              {reports[0]?.weeklyUsages
                .slice(0, WEEKS_COUNT)
                .reverse()
                .map(({ week, weekYear }) => (
                  <Td key={week + weekYear} centered bold>{`Week ${week}`}</Td>
                ))}
            </Tr>
            <Tr>
              <Td empty />
              {reports[0]?.weeklyUsages
                .slice(0, WEEKS_COUNT)
                .reverse()
                .map(({ sunday }) => (
                  <Td key={sunday} centered>
                    {formatWeek(sunday)}
                  </Td>
                ))}
            </Tr>
          </thead>
          <tbody>
            {sortReports(executiveReports).map(
              ({ id, workspace, weeklyUsages }) => (
                <Tr key={id} isFaded={!!workspace?.executives[0].principalUser}>
                  <Td bold>{getWorkspaceLabel(workspace)}</Td>
                  {weeklyUsages
                    .slice(0, WEEKS_COUNT)
                    .reverse()
                    .map(({ hours, week }) => (
                      <Td key={week} centered>
                        {formatNumber(hours)}
                      </Td>
                    ))}
                </Tr>
              ),
            )}
            <Tr>
              <Td bold grey>
                {'Total Executives'}
              </Td>
              {executiveReports
                .filter(({ workspace }) => !!workspace)
                .reduce((sumWeeklyUsages: number[], { weeklyUsages }) => {
                  weeklyUsages
                    .slice(0, WEEKS_COUNT)
                    .reverse()
                    .forEach(({ hours }, index) => {
                      sumWeeklyUsages[index] =
                        hours + (sumWeeklyUsages[index] || 0)
                    })
                  return sumWeeklyUsages
                }, [])
                .map((hours, index) => (
                  <Td key={index} centered grey>
                    {formatNumber(hours)}
                  </Td>
                ))}
            </Tr>
            {otherReports.map(({ id, workspace, weeklyUsages }) => (
              <Tr key={id}>
                <Td bold>{getWorkspaceLabel(workspace)}</Td>
                {weeklyUsages
                  .slice(0, WEEKS_COUNT)
                  .reverse()
                  .map(({ hours, week }) => (
                    <Td key={week} centered>
                      {formatNumber(hours)}
                    </Td>
                  ))}
              </Tr>
            ))}
          </tbody>
          <tfoot>
            <Tr>
              <Td bold grey>
                {'Total'}
              </Td>
              {reports
                .reduce((sumWeeklyUsages: number[], { weeklyUsages }) => {
                  weeklyUsages
                    .slice(0, WEEKS_COUNT)
                    .reverse()
                    .forEach(({ hours }, index) => {
                      sumWeeklyUsages[index] =
                        hours + (sumWeeklyUsages[index] || 0)
                    })
                  return sumWeeklyUsages
                }, [])
                .map((hours, index) => (
                  <Td key={index} centered grey>
                    {formatNumber(hours)}
                  </Td>
                ))}
            </Tr>
          </tfoot>
        </Table>
      </FormSection>

      <FormSection title={`Last ${MONTHS_COUNT} months`}>
        <Table cellSpacing={'0'} cellPadding={'0'}>
          <thead>
            <Tr>
              <Td empty />
              {reports[0]?.monthlyUsages
                .slice(0, MONTHS_COUNT)
                .reverse()
                .map(({ month, year }) => (
                  <Td key={year + month} centered bold>
                    {formatMonth(month)}
                  </Td>
                ))}
            </Tr>
          </thead>
          <tbody>
            {sortReports(executiveReports).map(
              ({ id, workspace, monthlyUsages }) => (
                <Tr key={id} isFaded={!!workspace?.executives[0].principalUser}>
                  <Td bold>{getWorkspaceLabel(workspace)}</Td>
                  {monthlyUsages
                    .slice(0, MONTHS_COUNT)
                    .reverse()
                    .map(({ hours, month }) => (
                      <Td key={month} centered>
                        {formatNumber(hours)}
                      </Td>
                    ))}
                </Tr>
              ),
            )}
            <Tr>
              <Td bold grey>
                {'Total Executives'}
              </Td>
              {executiveReports
                .filter(({ workspace }) => !!workspace)
                .reduce((sumMonthlyUsages: number[], { monthlyUsages }) => {
                  monthlyUsages
                    .slice(0, MONTHS_COUNT)
                    .reverse()
                    .forEach(({ hours }, index) => {
                      sumMonthlyUsages[index] =
                        hours + (sumMonthlyUsages[index] || 0)
                    })
                  return sumMonthlyUsages
                }, [])
                .map((hours, index) => (
                  <Td key={index} centered grey>
                    {formatNumber(hours)}
                  </Td>
                ))}
            </Tr>
            {otherReports.map(({ id, workspace, monthlyUsages }) => (
              <Tr key={id}>
                <Td bold>{getWorkspaceLabel(workspace)}</Td>
                {monthlyUsages
                  .slice(0, MONTHS_COUNT)
                  .reverse()
                  .map(({ hours, month }) => (
                    <Td key={month} centered>
                      {formatNumber(hours)}
                    </Td>
                  ))}
              </Tr>
            ))}
          </tbody>
          <tfoot>
            <Tr>
              <Td bold grey>
                {'Total'}
              </Td>
              {reports
                .reduce((sumMonthlyUsages: number[], { monthlyUsages }) => {
                  monthlyUsages
                    .slice(0, MONTHS_COUNT)
                    .reverse()
                    .forEach(({ hours }, index) => {
                      sumMonthlyUsages[index] =
                        hours + (sumMonthlyUsages[index] || 0)
                    })
                  return sumMonthlyUsages
                }, [])
                .map((hours, index) => (
                  <Td key={index} centered grey>
                    {formatNumber(hours)}
                  </Td>
                ))}
            </Tr>
          </tfoot>
        </Table>
      </FormSection>

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Reporting
