import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const ThisCycleOuter = styled.div`
  background-color: ${colors.N20};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;

  h3 {
    margin-top: 0;
  }
`

export const Legend = styled.div`
  display: flex;
  justify-content: space-between;
`
