import SectionMessage from '@atlaskit/section-message'
import React, { useState } from 'react'

import Modal from '../../../../components/Modal'
import TeamSelect from '../../../../components/teams/Select'
import {
  useUpdateWorkspaceTeamMutation,
  WorkspaceWithTeamFragment,
} from '../../../../graphql'

type Props = {
  workspace: WorkspaceWithTeamFragment
  onClose: () => void
}

const UpdateWorkspaceTeamModal = ({ workspace, onClose }: Props) => {
  const [teamId, setTeamId] = useState(workspace.team?.id)

  const [updateWorkspaceTeam, { loading }] = useUpdateWorkspaceTeamMutation({
    onCompleted: () => onClose(),
  })

  return (
    <Modal
      isOpen
      heading={'Update the team for this workspace'}
      onClose={onClose}
      actions={[
        {
          text: 'Save',
          isDisabled: !teamId || workspace.team?.id === teamId || loading,
          onClick: () =>
            teamId &&
            updateWorkspaceTeam({
              variables: {
                input: {
                  workspaceId: workspace.id,
                  teamId,
                },
              },
            }),
        },
      ]}
    >
      <TeamSelect
        value={teamId ? { id: teamId } : undefined}
        onChange={(team) => team && setTeamId(team.id)}
        maxMenuHeight={250}
      />
      {/* 💩 For the select suggestions list to be visible */}
      <div style={{ height: 180 }} />
      <SectionMessage
        title={'Changing the team will affect billing'}
        appearance={'information'}
      >
        {
          'This workspace’s time entries from the current billing cycle will be associated with the new team’s plan.'
        }
      </SectionMessage>
    </Modal>
  )
}

export default UpdateWorkspaceTeamModal
