import { FormSection } from '@atlaskit/form'
import { ComponentProps, useMemo } from 'react'

import ErrorBanner from '../../../../components/ErrorBanner'
import Spinner from '../../../../components/Spinner'
import { Outer, Table, Td, Tr } from '../../../../components/reporting/styled'
import { formatNumber } from '../../../../components/reporting/utils'
import { DateType, formatDate } from '../../../../components/utils'
import { useGetTeamReportingQuery } from '../../../../graphql'

import CurrentCycleProgressBar from './CurrentCycleProgressBar'
import { SpinnerOuter } from './styled'

interface Props extends ComponentProps<'div'> {
  teamId: string
}

const MONTHS_COUNT = 4

const Reporting = ({ teamId }: Props) => {
  const { data, loading, error } = useGetTeamReportingQuery({
    variables: {
      teamId,
    },
  })

  const { subscription, workspaces } = data?.team || {}
  const currentBillingCycle = subscription?.currentBillingCycle
  const { startDate, endDate, allocatedHours } = currentBillingCycle || {}

  const teamWorkspaces = workspaces?.filter((workspace) => !workspace.isPool)

  const firstMonthlyUsage =
    teamWorkspaces &&
    teamWorkspaces[0].usage?.monthlyUsages.slice(0, MONTHS_COUNT).reverse()

  const totals = useMemo(() => {
    if (!teamWorkspaces) return []

    return teamWorkspaces.reduce((sumMonthlyUsages: number[], { usage }) => {
      usage.monthlyUsages
        .slice(0, MONTHS_COUNT)
        .reverse()
        .forEach(({ hours }, index) => {
          sumMonthlyUsages[index] = hours + (sumMonthlyUsages[index] || 0)
        })
      return sumMonthlyUsages
    }, [])
  }, [teamWorkspaces])

  // Todo: get usage hours from MonthlyUsages
  const currentCycleUsageHours = totals[MONTHS_COUNT - 1] || 0

  return (
    <Outer>
      {error && <ErrorBanner error={error} />}
      {loading ? (
        <SpinnerOuter>
          <Spinner size={'large'} />
        </SpinnerOuter>
      ) : (
        <>
          {allocatedHours && startDate && endDate && (
            <CurrentCycleProgressBar
              usageHours={currentCycleUsageHours}
              allocatedHours={allocatedHours}
              usagePercentage={currentCycleUsageHours / allocatedHours}
              startDate={startDate}
              endDate={endDate}
            />
          )}

          <FormSection title={`Last ${MONTHS_COUNT} Billing Cycles`}>
            <Table cellSpacing={'0'} cellPadding={'0'}>
              <thead>
                <Tr>
                  <Td empty />
                  {firstMonthlyUsage?.map(({ startDate, endDate }) => (
                    <Td key={startDate + endDate} centered bold>
                      {`${formatDate(startDate, DateType.SHORT)} - ${formatDate(
                        endDate,
                        DateType.SHORT,
                      )}`}
                    </Td>
                  ))}
                </Tr>
              </thead>
              <tbody>
                {teamWorkspaces &&
                  teamWorkspaces.map(({ executives, usage }, index) => (
                    <Tr key={index}>
                      <Td bold>
                        {executives[0].profile.displayName ||
                          `Executive ${index}`}
                      </Td>
                      {usage.monthlyUsages
                        .slice(0, MONTHS_COUNT)
                        .reverse()
                        .map((usage, index) => (
                          <Td key={index} centered>
                            {formatNumber(usage.hours)}
                          </Td>
                        ))}
                    </Tr>
                  ))}
              </tbody>
              <tfoot>
                <Tr>
                  <Td bold grey>
                    {'Total'}
                  </Td>
                  {totals.map((value, index) => (
                    <Td key={index} bold centered grey>
                      {formatNumber(value)}
                    </Td>
                  ))}
                </Tr>
              </tfoot>
            </Table>
          </FormSection>
        </>
      )}
    </Outer>
  )
}

export default Reporting
