import { DatePicker } from '@atlaskit/datetime-picker'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import Modal, { Props as ModalProps } from '../../../../components/Modal'
import { ZIRTUAL_CHURN_REASONS } from '../../../../components/churn/constants'
import { Field, TextArea, Select } from '../../../../components/form'
import {
  FullUserFragment,
  ChurnExecutiveInput,
  useChurnExecutiveMutation,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'

const Notice = styled.p`
  font-size: smaller;
  ul {
    padding-left: 16px;
    li {
      list-style: disc;
    }
  }
`

type Props = ModalProps & {
  user: FullUserFragment
}

const ChurnExecutiveModal = ({
  user,
  onClose,
  onCloseComplete: _onCloseComplete,
  ...modalProps
}: Props) => {
  const [values, { setters, reset }] = useValues<Partial<ChurnExecutiveInput>>(
    {},
    ['notepad', 'churnDecisionOn', 'reason'],
  )

  const [churnExecutive, { loading }] = useChurnExecutiveMutation({
    onCompleted: () => {
      // reload the page to get the latest autopilot jobs data
      window.location.reload()
    },
  })

  const onRecordChurn = useCallback(() => {
    const { churnDecisionOn, reason, ...input } = values
    if (user?.id && reason) {
      churnExecutive({
        variables: {
          input: {
            userId: user.id,
            ...input,
            reason,
            churnDecisionOn,
          },
        },
      })
    }
  }, [churnExecutive, user, values])

  const actions = useMemo(
    () => [
      {
        text: 'Schedule churn',
        isDisabled: !(user?.id && values.reason),
        isLoading: loading,
        onClick: onRecordChurn,
      },
      { text: 'Cancel', onClick: onClose },
    ],
    [onClose, onRecordChurn, loading, user?.id, values.reason],
  )

  return (
    <Modal
      actions={actions}
      heading={`Is ${user?.profile.displayName} churning?`}
      appearance={'danger'}
      width={'small'}
      autoFocus={false}
      onCloseComplete={(e) => [reset(), _onCloseComplete?.(e)]}
      onClose={onClose}
      {...modalProps}
    >
      <Field
        label={'Churn Date'}
        isRequired
        helperMessage={
          'The day the exec communicated their decision to churn. Grants grace from full month billing if communicate early in the month.'
        }
      >
        <DatePicker
          value={values.churnDecisionOn ?? DateTime.local().toISODate()}
          onChange={setters.churnDecisionOn}
        />
      </Field>
      <Field
        label={'Reason'}
        isRequired
        helperMessage={'Will be recorded in Airtable'}
      >
        <Select
          options={ZIRTUAL_CHURN_REASONS.map((r) => ({ label: r, value: r }))}
          value={{ label: values.reason, value: values.reason }}
          onChangeValue={setters.reason}
          maxMenuHeight={190}
        />
      </Field>
      <Field label={'Notes'} helperMessage={'Will be recorded in Airtable'}>
        <TextArea
          value={values.notepad || undefined}
          onChangeValue={setters.notepad}
          rows={4}
        />
      </Field>

      <br />
      <Notice>
        {'Scheduling a churn will:'}
        <ul>
          <li>{'Create a generic note in Airtable.'}</li>
          <li>
            {'Immediately post to HQ Slack in #churn to let the team know.'}
          </li>
          <li>
            {
              'Run the churn automation at the end of the month of the churn date, adding 24hrs if the churn date is on the last day of the month.'
            }
          </li>
          <li>
            {
              'The automation updates the client status and unlink Double in Airtable, and archives the executive (which also archives their workspaces) in Cockpit. The action is reversible, but not easily.'
            }
          </li>
          <li>
            {
              "You will be able to cancel a scheduled churn automation until it's scheduled date."
            }
          </li>
        </ul>
      </Notice>
    </Modal>
  )
}

export default ChurnExecutiveModal
