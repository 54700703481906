import CompletedIcon from '@atlaskit/icon/glyph/check-circle'
import NotCompletedIcon from '@atlaskit/icon/glyph/check-circle-outline'
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle'
import { colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import React, { useCallback } from 'react'
import styled from 'styled-components'

const Outer = styled.div<{ withPointer?: boolean }>`
  ${({ withPointer }) => withPointer && 'cursor: pointer;'}
`

type Props = {
  isCompleted: boolean | undefined | null
  onComplete?: (isChecked: boolean) => void
  confirmToggle?: boolean
  tooltip?: string
}

const StepToggle = ({
  isCompleted,
  onComplete,
  confirmToggle,
  tooltip,
}: Props) => {
  const completeError = isCompleted === null
  const canComplete = !isCompleted && onComplete
  const Icon = completeError
    ? QuestionCircleIcon
    : isCompleted
    ? CompletedIcon
    : canComplete
    ? CompletedIcon
    : NotCompletedIcon

  const toggle = useCallback(() => {
    if (
      canComplete &&
      (!confirmToggle || window.confirm(`${tooltip || 'Sure'}?`))
    ) {
      onComplete?.(!isCompleted)
    }
  }, [canComplete, confirmToggle, tooltip, onComplete, isCompleted])

  return (
    <Tooltip content={canComplete && tooltip}>
      <Outer
        onClick={canComplete ? toggle : undefined}
        withPointer={!!canComplete}
      >
        <Icon
          label={''}
          primaryColor={
            isCompleted
              ? colors.green()
              : completeError
              ? colors.yellow()
              : colors.N40
          }
        />
      </Outer>
    </Tooltip>
  )
}

export default StepToggle
