import ProgressBar from '@atlaskit/progress-bar'
import { DateTime } from 'luxon'

import { formatNumber } from '../../../../../components/reporting/utils'
import { formatDate, DateType } from '../../../../../components/utils'

import { ThisCycleOuter, Legend } from './styled'

type Props = {
  usageHours: number
  allocatedHours: number
  usagePercentage: number
  startDate: string | Date
  endDate: string | Date
}

const formattedDate = (date: DateTime) => {
  return formatDate(
    date.setZone('America/New_York').toISODate(),
    DateType.SHORT,
  )
}

const CurrentCycleProgressBar = ({
  usageHours,
  allocatedHours,
  startDate,
  endDate,
}: Props) => {
  const usagePercentage = usageHours / (allocatedHours || 1)
  const startDateDateTime =
    typeof startDate === 'string'
      ? DateTime.fromISO(startDate)
      : DateTime.fromJSDate(startDate)
  const endDateDateTime =
    typeof endDate === 'string'
      ? DateTime.fromISO(endDate)
      : DateTime.fromJSDate(endDate)
  const formattedStartDate = formattedDate(startDateDateTime)
  const formattedEndDate = formattedDate(endDateDateTime)
  return (
    <ThisCycleOuter>
      <h3>{`Current cycle: ${formatNumber(
        usageHours,
      )}h of ${allocatedHours}h used `}</h3>
      <ProgressBar value={usagePercentage} />
      <Legend>
        <div>{formattedStartDate}</div>
        <div>{formattedEndDate}</div>
      </Legend>
    </ThisCycleOuter>
  )
}

export default CurrentCycleProgressBar
